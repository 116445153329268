import { Link } from "gatsby";
import React from "react";

const HaloAnnouncement = ({ announcement }) => {
  return (
    <div className="halo-announcement-wrapper w-90-wrapper">
      <div className="left-portion-wrapper">
        <img src={announcement?.image} alt="Goken America Latest Annoncement" />
      </div>
      <div className="right-portion-wrapper">
        <div className="annoucement-tag-wrapper">
          <h2 className="title fw-700 margin-0">Latest from Goken</h2>
        </div>

        <div className="content-wrapper">
          <h3 className="heading margin-0 regularcase-text    ">
            {announcement?.heading}
          </h3>
          <p className="para margin-0">{announcement?.para}</p>
        </div>
        <div className="cta-wrapper">
          <Link
            to={announcement?.link}
            className="wipe-btn-black wipe-black lft-rgt-wipe"
          >
            {announcement?.linkText}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HaloAnnouncement;
