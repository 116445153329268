import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";

// Images
import ImageOne from "../../../images/homepageassets/Solution-Product-Development.png";
import ImageTwo from "../../../images/homepageassets/solutions.png";
import ImageThree from "../../../images/homepageassets/Solution-Staffing.png";
import ImageFour from "../../../images/homepageassets/Solution-Partnership.png";

import RightArrLong from "../../../images/right-arrow-long.svg";

const HomepageSolutions = () => {
  const [onHoverOne, setOnHoverOne] = useState(false);
  const [onHoverTwo, setOnHoverTwo] = useState(false);
  const [onHoverThree, setOnHoverThree] = useState(false);
  const [onHoverFour, setOnHoverFour] = useState(false);
  const solutions = [
    {
      title: "Product Development",
      paragraph:
        "Our engineering expertise can help you build exceptional products​",
      image: ImageOne,
      link: "solutions/productdevelopment/",
    },
    {
      title: "Productivity",
      paragraph:
        "Utilize our process efficiency improvements and automation to accelerate your development cycle and maximize efficiency",
      image: ImageTwo,
      link: "solutions/productivity/",
    },
    {
      title: "Staffing",
      paragraph:
        "We find you the right people not only for the job, but your culture as well​",
      image: ImageThree,
      link: "services/staffing/",
    },
    {
      title: "Partnerships",
      paragraph:
        "Leverage our partnerships to gain access and transition to the top of the line up to date sofwares and systems",
      image: ImageFour,
      link: "/contactus/",
    },
  ];

  return (
    <div className="section section-br">
      <div className="w-90-wrapper">
        <div className="solutions-wrapper">
          <Fade bottom cascade>
            <h2 className="heading heading-bottom-space">
              How we serve your needs
            </h2>
          </Fade>

          <div className="solution-wrapper">
            <div className="solution-inner-wrapper">
              {solutions.map((solution) => (
                <Link to={solution?.link ? solution?.link : ""}>
                  <div className="solution" key={solution?.title}>
                    <div className="image">
                      <img src={solution?.image} alt="Solutions Image"></img>
                    </div>
                    <div className="desc-wrapper ">
                      <div className="desc">
                        <h2 className="desc-heading no-margin heading-bottom-space bold semi-title bold heading-bottom-space">
                          {solution?.title}
                        </h2>
                        <p className="no-margin regular text-center semi-para common-bottom-space">
                          {solution?.paragraph}
                        </p>

                        <h3 className="knowmore-btn semi-para normal regular">
                          Learn More{" "}
                          <img
                            src={RightArrLong}
                            alt="Learn More Arrow which will show more services"
                          ></img>
                        </h3>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
              {/* <Link to={solutions[0].link ? solutions[0].link : ""}>
                <div className="solution" key={solutions[0].title}>
                  <div className="image">
                    <img src={solutions[0].image} alt="Solutions Image"></img>
                  </div>
                  <div className="desc-wrapper ">
                    <div className="desc">
                      <h2 className="desc-heading no-margin heading-bottom-space bold semi-title bold heading-bottom-space">
                        {solutions[0].title}
                      </h2>
                      <p className="no-margin regular text-center semi-para common-bottom-space">
                        {solutions[0].paragraph}
                      </p>

                      <div className="knowmore-btn semi-para ">
                        Learn More <img src={RightArrLong}></img>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={solutions[1].link ? solutions[1].link : ""}>
                <div className="solution" key={solutions[1].title}>
                  <div className="image">
                    <img src={solutions[1].image} alt="Solutions Image"></img>
                  </div>
                  <div className="desc-wrapper ">
                    <div className="desc">
                      <h2 className="desc-heading no-margin heading-bottom-space bold semi-title">
                        {solutions[1].title}
                      </h2>
                      <p className="no-margin regular text-center semi-para">
                        {solutions[1].paragraph}
                      </p>

                      <div className="knowmore-btn semi-para ">
                        Learn More <img src={RightArrLong}></img>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={solutions[2].link ? solutions[2].link : ""}>
                <div className="solution" key={solutions[2].title}>
                  <div className="image">
                    <img src={solutions[2].image} alt="Solutions Image"></img>
                  </div>
                  <div className="desc-wrapper ">
                    <div className="desc">
                      <h2 className="desc-heading no-margin heading-bottom-space bold semi-title">
                        {solutions[2].title}
                      </h2>
                      <p className="no-margin regular text-center semi-para">
                        {solutions[2].paragraph}
                      </p>

                      <div className="knowmore-btn semi-para ">
                        Learn More <img src={RightArrLong}></img>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={solutions[3].link ? solutions[3].link : ""}>
                <div className="solution" key={solutions[3].title}>
                  <div className="image">
                    <img src={solutions[3].image} alt="Solutions Image"></img>
                  </div>
                  <div className="desc-wrapper ">
                    <div className="desc">
                      <h2 className="desc-heading no-margin heading-bottom-space bold semi-title">
                        {solutions[3].title}
                      </h2>
                      <p className="no-margin regular text-center semi-para">
                        {solutions[3].paragraph}
                      </p>

                      <div className="knowmore-btn semi-para ">
                        Learn More <img src={RightArrLong}></img>
                      </div>
                    </div>
                  </div>
                </div>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageSolutions;
