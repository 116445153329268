import React from "react";
import HaloAnnouncement from "./haloAnnouncement";

const Announcement = ({ announcement }) => {
  return (
    <div className="goken__announcement-wrapper">
      <div className="announcement-container">
        <HaloAnnouncement announcement={announcement} />
      </div>
    </div>
  );
};

export default Announcement;
