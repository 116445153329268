import { Link } from "gatsby";
import React from "react";
import WipeBtn from "../buttons/WipeBtn";
import Fade from "react-reveal/Fade";

const IndustrySlide = ({
  heading,
  insightPageTitle,
  img,
  paragraph,
  link,
  pageLink,
  rightWhiteIcon,
  visible,
  mobImg,
  xlImg,
}) => {
  return (
    visible && (
      <Fade duration={1000}>
        <div className="work-slider-wrapper" style={{ marginRight: "5%" }}>
          <div className="work-content-images">
            <div className="tab-heading">
              <div className="tab-cta">
                <div className="title-oswald industry-heading">
                  <h4 className="heading-left">{heading ? heading : ""}</h4>
                  {link !== undefined ? (
                    <h4 className="heading-right regular"> Case Studies</h4>
                  ) : null}
                </div>
                <p className="cta-heading semi-para industry-para">
                  {link !== undefined
                    ? insightPageTitle
                      ? insightPageTitle
                      : null
                    : null}
                </p>
                <div className="link-btn semi-para ">
                  {link !== undefined ? (
                    <Link to={link ? link : "#"}>
                      View{" "}
                      <img
                        src={rightWhiteIcon}
                        alt={"View Case Study button with this image."}
                      ></img>
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="image-wrapper">
              <img src={img} alt={heading ?? "Goken's Industry Services"}></img>
            </div>

            <div className="mobile-image-wrapper">
              <img
                src={mobImg}
                alt={heading ?? "Goken's Industry Services"}
              ></img>
            </div>
            <div className="xl-image-wrapper">
              <img
                src={xlImg}
                alt={heading ?? "Goken's Industry Services"}
              ></img>
            </div>
          </div>

          <div className="tab-content-wrapper">
            <div>
              <div className="content">
                <p className="para common-bottom-space">
                  {paragraph ? paragraph : ""}
                </p>
                <div className="btn-wrapper">
                  <WipeBtn
                    text="Explore"
                    className={"green-border green-btn bg-green-btn"}
                    url={pageLink}
                  ></WipeBtn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    )
  );
};
export default IndustrySlide;
