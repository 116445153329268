import React from "react";
// import { Link } from "gatsby";
import WipeBtn from "../components/atoms/buttons/WipeBtn";

function HeaderWithVideo({
  headingOne,
  headingBold,
  headingTwo,
  headingThree,
  para,
  url,
  btnTxt,
  video,
  preHeading,
  border = true,
}) {
  console.log(video, "video");
  return (
    <div className="header-with-video-container">
      <div className="content-intro w-90-wrapper ">
        <div
          className={`gokengo-header-title ${
            border ? "left-green-border" : ""
          }`}
        >
          {preHeading && <h1 className="heading">{preHeading}</h1>}
          {headingOne && (
            <h1 className="heading-normal heading">{headingOne}</h1>
          )}
          {headingBold && <h2 className="normal-heading">{headingBold}</h2>}
          {headingTwo && (
            <h2 className="heading-normal heading">{headingTwo}</h2>
          )}
          {headingThree && (
            <h2 className="heading-normal heading">{headingThree} </h2>
          )}
          <p className="para"> {para} </p>
        </div>
        <div className="header-btn-container">
          <WipeBtn
            text={btnTxt}
            className={"green-border green-btn bg-green-btn"}
            url={url}
          />
        </div>
      </div>
      <div className="intro-video-wrapper">
        <video
          className="new-goken-go-video"
          src={video.url}
          loop
          autoPlay
          muted
          Subtitles
          controls
        ></video>
      </div>
    </div>
  );
}

export default HeaderWithVideo;
