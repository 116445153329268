import React, { useState, useEffect } from "react";

// image
import Fade from "react-reveal/Fade";
import WipeBtn from "../../../components/atoms/buttons/WipeBtn";
import BrandingVideo from "../../../videos/BrandingV.mp4";

import { useStaticQuery, graphql } from "gatsby";

const HomepageHero = () => {
  const data = useStaticQuery(graphql`
    query GetSliderData {
      gcms {
        homePageSlider(where: { id: "ckfnsq90o0dfs0119ig41n0xy" }) {
          slideOneHeading
          slideOneLink
          slideOneParagraph
          slideOneTagline
          slideThreeHeading
          slideThreeLink
          slideThreeParagraph
          slideThreeTagline
          slideTwoHeading
          slideTwoLink
          slideTwoParagraph
          slideTwoTagline
          slideOneImage {
            url
          }
          slideTwoImage {
            url
          }
          slideThreeImage {
            url
          }
        }
      }
    }
  `);

  const [showSlideOne, setShowSlideOne] = useState(true);
  const [showSlideTwo, setShowSlideTwo] = useState(false);
  const [showSlideThree, setShowSlideThree] = useState(false);
  const [showSlideFour, setShowSlideFour] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  // const [thirdVisited, setThirdVisited] = useState(false);

  const [seconds, setSeconds] = useState(15);
  // useEffect(() => {
  //   // const listener = () => {
  //   //   setFullScreen(true);
  //   // };
  //   // window.addEventListener("fullscreenchange", listener);

  //   // return () => {
  //   //   window.removeEventListener("fullscreenchange", listener);
  //   // };
  // }, [setFullScreen]);
  var index = 1;
  //   let currentSeconds = seconds;
  // const [seconds, setSeconds] = useState(15);
  let currentSeconds = seconds;

  // useEffect(() => {
  //   // let time = setInterval(20)
  //   // let seconds = 20;
  //   if (seconds > 0) {
  //     setTimeout(() => setSeconds(seconds - 1), 1000);
  //   } else {
  //     if (!currentSeconds > 0) {
  //       // setThirdVisited(false);
  //       if (index === 3) setSeconds(120);
  //       else setSeconds(15);
  //       if (index === 4) index = 0;

  //       changeSlide(index + 1);
  //       // setSeconds(15);
  //     }

  //     // if (showSlideOne) {
  //     //   changeToSlideTwo();
  //     // } else if (showSlideTwo) {
  //     //   changeToSlideThree();
  //     // } else if (showSlideThree) {
  //     //   setThirdVisited(true);
  //     //   changeToSlideFour();
  //     //   setSeconds(120);

  //     //   return;
  //     //   // changeToSlideOne();
  //     // } else if (showSlideFour) {
  //     //   if (!thirdVisited) {
  //     //     setSeconds(120);
  //     //     return;
  //     //   }

  //     //   changeToSlideOne();
  //     // } else {
  //     //   changeToSlideOne();
  //     // }

  //     // setSeconds(15);
  //   }
  // });

  const changeToSlideOne = (e) => {
    console.log("change to one called", e);
    turnOffAll();
    setShowSlideOne(true);
    setSeconds(15);
    clearTimeout(myTimeout);
  };

  const changeToSlideTwo = () => {
    turnOffAll();
    setShowSlideTwo(true);

    setSeconds(15);
    clearTimeout(myTimeout);
  };
  // const openCardTwo = () => {
  //   turnOffAll();
  //   setCardTwo(true);
  //   setSeconds(15);
  //   clearTimeout(myTimeout);
  // };
  const changeToSlideThree = () => {
    turnOffAll();
    setShowSlideThree(true);
    setSeconds(15);
    clearTimeout(myTimeout);
  };
  const changeToSlideFour = () => {
    turnOffAll();
    setShowSlideFour(true);
    setSeconds(15);
    clearTimeout(myTimeout);
  };
  function turnOffAll() {
    setShowSlideOne(false);
    setShowSlideTwo(false);
    setShowSlideThree(false);
    setShowSlideFour(false);
  }
  if (seconds > 0) {
    var myTimeout = setTimeout(() => setSeconds(seconds - 1), 1000);
  } else {
    if (!currentSeconds > 0) {
      console.log("Index value is ", index, seconds);
      if (!showSlideFour) {
        if (showSlideOne) {
          changeToSlideTwo();
        }
        if (showSlideTwo) {
          changeToSlideThree();
        }
        if (showSlideThree) {
          changeToSlideFour();
        }
      } else {
        try {
          // endVideo();
          console.log("Active slide 4", seconds);
          let vid = document.querySelector(".goken-new-video");
          let duration = vid.duration;
          console.log(
            "Active video slide currenttime , video durationi s",
            vid.currentTime,
            duration,
            vid
          );
          if (vid.currentTime === duration) {
            changeToSlideOne();
          }
          // let vid = document.querySelector(".associate-vid");
          // let duration = vid.duration;

          // // if (vid.currentTime > duration) {

          // //   // openCardOne();
          // // }
        } catch (error) {
          console.log("active error ", error);
        }
        // openCardOne();
      }
      // setSeconds(15);
    }
  }
  useEffect(() => {}, [seconds]);

  return (
    <div className="hero-section">
      <div className=" bg-primary">
        <h1 className="hidden-block">About Us</h1>
        <div className="w-90-wrapper">
          {data && (
            <div className="slide-container">
              {/* Slide 1 */}
              {showSlideOne ? (
                <div className="slide">
                  <div className="slide-info">
                    <div className="semi-para tagline">
                      <p>{data.gcms.homePageSlider.slideOneTagline}</p>
                    </div>
                    <Fade duration={1000}>
                      <h2 className="heading heading-bottom-space">
                        {data.gcms.homePageSlider.slideOneHeading}
                      </h2>
                    </Fade>
                    <Fade duration={1000}>
                      <p className="para common-bottom-space m-top-10px">
                        {data.gcms.homePageSlider.slideOneParagraph}
                      </p>
                    </Fade>
                    {
                      //comment from here to stop slides
                      <div className="slide-square">
                        <div
                          onClick={(e) => changeToSlideOne(e)}
                          className={`square ${showSlideOne ? "active" : ""}`}
                        ></div>
                        <div
                          onClick={changeToSlideTwo}
                          className={`square ${showSlideTwo ? "active" : ""}`}
                        ></div>
                        <div
                          onClick={changeToSlideThree}
                          className={`square ${showSlideThree ? "active" : ""}`}
                        ></div>
                        <div
                          onClick={changeToSlideFour}
                          className={`square ${showSlideFour ? "active" : ""}`}
                        ></div>
                      </div>
                      //comment till here
                    }
                    <div className="btn">
                      <WipeBtn text="Contact Us" url={"/contactus/"}></WipeBtn>
                    </div>
                  </div>
                  <Fade right>
                    <div className="slide-img">
                      <img
                        src={
                          data.gcms.homePageSlider.slideOneImage &&
                          data.gcms.homePageSlider.slideOneImage.url
                        }
                        alt={data.gcms.homePageSlider.slideOneTagline}
                      ></img>
                    </div>
                  </Fade>
                </div>
              ) : (
                ""
              )}
              {/* Slide 2 */}

              {showSlideTwo ? (
                <div className="slide">
                  <div className="slide-info">
                    <div className="semi-para tagline">
                      <p>{data.gcms.homePageSlider.slideTwoTagline}</p>
                    </div>
                    <Fade duration={1000}>
                      <h2 className="heading heading-bottom-space">
                        {data.gcms.homePageSlider.slideTwoHeading}
                      </h2>
                    </Fade>
                    <Fade duration={1000}>
                      <p className="para common-bottom-space">
                        {data.gcms.homePageSlider.slideTwoParagraph}
                      </p>
                    </Fade>
                    <div className="slide-square">
                      <div
                        onClick={(e) => changeToSlideOne(e)}
                        className={`square ${showSlideOne ? "active" : ""}`}
                      ></div>
                      <div
                        onClick={changeToSlideTwo}
                        className={`square ${showSlideTwo ? "active" : ""}`}
                      ></div>
                      <div
                        onClick={changeToSlideThree}
                        className={`square ${showSlideThree ? "active" : ""}`}
                      ></div>
                      <div
                        onClick={changeToSlideFour}
                        className={`square ${showSlideFour ? "active" : ""}`}
                      ></div>
                    </div>
                    <div className="btn">
                      <WipeBtn text="Contact Us" url={"/contactus/"} />
                    </div>
                  </div>
                  <Fade right>
                    <div className="slide-img">
                      <img
                        src={
                          data.gcms.homePageSlider.slideTwoImage &&
                          data.gcms.homePageSlider.slideTwoImage.url
                        }
                        alt={data.gcms.homePageSlider.slideTwoTagline}
                      ></img>
                    </div>
                  </Fade>
                </div>
              ) : (
                ""
              )}
              {/* Slide 3 */}
              {showSlideThree ? (
                <div className="slide">
                  <div className="slide-info">
                    <div className="semi-para tagline">
                      <p>{data.gcms.homePageSlider.slideThreeTagline}</p>
                    </div>
                    <Fade duration={1000}>
                      <h2 className="heading heading-bottom-space">
                        {data.gcms.homePageSlider.slideThreeHeading}
                      </h2>
                    </Fade>

                    <Fade duration={1000}>
                      <p className="para common-bottom-space">
                        {data.gcms.homePageSlider.slideThreeParagraph}
                      </p>
                    </Fade>
                    <div className="slide-square">
                      <div
                        onClick={(e) => changeToSlideOne(e)}
                        className={`square ${showSlideOne ? "active" : ""}`}
                      ></div>
                      <div
                        onClick={changeToSlideTwo}
                        className={`square ${showSlideTwo ? "active" : ""}`}
                      ></div>
                      <div
                        onClick={changeToSlideThree}
                        className={`square ${showSlideThree ? "active" : ""}`}
                      ></div>
                      <div
                        onClick={changeToSlideFour}
                        className={`square ${showSlideFour ? "active" : ""}`}
                      ></div>
                    </div>
                    <div className="btn">
                      <WipeBtn text="Contact Us" url={"/contactus/"}></WipeBtn>
                    </div>
                  </div>
                  <Fade right>
                    <div className="slide-img">
                      <img
                        src={
                          data.gcms.homePageSlider.slideThreeImage &&
                          data.gcms.homePageSlider.slideThreeImage.url
                        }
                        alt={data.gcms.homePageSlider.slideThreeTagline}
                      ></img>
                    </div>
                  </Fade>
                </div>
              ) : (
                ""
              )}
              {/* SLIDE 4 */}
              {showSlideFour ? (
                <div className="slide">
                  <div className="slide-info">
                    <div className="semi-para tagline">
                      <p className="semi-para tagline">About Us</p>
                    </div>
                    <h2 className="heading heading-bottom-space">
                      <Fade duration={1000}>Who is Goken ?</Fade>
                    </h2>
                    <Fade duration={1000}>
                      <p className="para common-bottom-space">
                        In just one minute, learn everything you need to know
                        about who we are, where we've been, and where we're
                        heading.​
                      </p>
                    </Fade>

                    <div className="slide-square">
                      <div
                        onClick={(e) => changeToSlideOne(e)}
                        className={`square ${showSlideOne ? "active" : ""}`}
                      ></div>
                      <div
                        onClick={changeToSlideTwo}
                        className={`square ${showSlideTwo ? "active" : ""}`}
                      ></div>
                      <div
                        onClick={changeToSlideThree}
                        className={`square ${showSlideThree ? "active" : ""}`}
                      ></div>
                      <div
                        onClick={changeToSlideFour}
                        className={`square ${showSlideFour ? "active" : ""}`}
                      ></div>
                    </div>
                    <div className="btn">
                      <WipeBtn text="Learn More" url={"/careers/"}></WipeBtn>
                    </div>
                  </div>
                  <Fade right>
                    <div className="slide-video">
                      <video
                        className={`goken-new-video`}
                        controls={true}
                        playsInline={true}
                        muted={true}
                        autoPlay={true}
                        src={BrandingVideo}
                      ></video>
                      {/* <ReactPlayer
                        url={BrandingVideo}
                        playing={true}
                        playIcon={true}
                        controls
                      /> */}
                    </div>
                  </Fade>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomepageHero;
