import { Link } from "gatsby";
import React from "react";
import { Fade } from "react-reveal";

const AboutUsSlide = ({
  duration,
  delay,
  index,
  changeIndex,
  heading,
  image,
  link,
  itemIndex,
}) => {
  return (
    <div className="slides-wrapper">
      {" "}
      <h2 className="heading">{heading}</h2>
      <Link to={link}>
        <div className="slide">
          <img src={image} alt={heading}></img>
        </div>
      </Link>
    </div>
  );
};
export default AboutUsSlide;
