import React, { useState } from "react";
import WipeBtn from "../../../components/atoms/buttons/WipeBtn";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";

import rightWhiteIcon from "../../../images/rightWhiteIcon.svg";
import { useStaticQuery, graphql } from "gatsby";

import workWithImgOne from "../../../images/homepageassets/aerospace.png";
import workWithImgTwo from "../../../images/homepageassets/homepage-automotive.png";
// import workWithImgThree from "../../../images/homepageassets/homepage-advanced-tech.png";
import workWithImgFour from "../../../images/homepageassets/homepage-manufacturing.png";
//import phone images
import workWithMobImgOne from "../../../images/homepageassets/aerospace-mobile.png";
import workWithMobImgTwo from "../../../images/homepageassets/automotive-mobile.png";
// import workWithMobImgThree from "../../../images/homepageassets/advanced-technology-mobile.png";
import workWithMobImgFour from "../../../images/homepageassets/manufacturing-mobile.png";

//import large size images
import workWithImgXLOne from "../../../images/homepageassets/aerospace-large.png";
import workWithImgXLTwo from "../../../images/homepageassets/automotive-large.png";
// import workWithImgXLThree from "../../../images/homepageassets/advanced-technology-large.png";
import workWithImgXLFour from "../../../images/homepageassets/manufacturing-large.png";
import IndustrySlide from "../../../components/atoms/homepage/IndustrySlide";
const HomepageWorkWith = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const cmsData = useStaticQuery(graphql`
    query homepageWorkWithData {
      gcms {
        homepageWhoWeWorkWiths {
          insightPages {
            slug
            stage
            title
          }
        }
      }
    }
  `);

  const data = [
    {
      heading: "AEROSPACE",
      paragraph:
        "Reduction in air traffic has deeply affected the aerospace industry. As a result, OEMs must optimize their inventory to match the reduced demand and review their product strategy in light of changing market needs.",
      img: workWithImgOne,
      mobImg: workWithMobImgOne,
      xlImg: workWithImgXLOne,
      link: "industry/aerospace/",
    },
    {
      heading: "AUTOMOTIVE",
      paragraph:
        "The automotive industry is rapidly transitioning from internal combustion engines to electric vehicles. This challenge, paired with changing models of customer adoption, pressures manufacturers to leverage trusted partners and unique solutions.​",
      img: workWithImgTwo,
      mobImg: workWithMobImgTwo,
      xlImg: workWithImgXLTwo,
      link: "industry/automotive/",
    },
    {
      heading: "MANUFACTURING",
      paragraph:
        "New and emerging technologies are creating a data-driven manufacturing environment in contrast to the past's greasy and grimy shop floor. The digital revolution is changing how the shop floor operates.",
      img: workWithImgFour,
      mobImg: workWithMobImgFour,
      xlImg: workWithImgXLFour,
      link: "industry/manufacturing/",
    },
    // {
    //   heading: "Advanced Technology",
    //   paragraph:
    //     "Few years ago there was a fear that automation in industries will cause job losses. However, automation and other digital technologies are ubiquitous and they have proved as productive tools that are disrupting how work is done.",
    //   img: workWithImgThree,
    //   xlImg: workWithImgXLThree,
    //   mobImg: workWithMobImgThree,
    //   link: "industry/advancedtechnology/",
    // },
  ];

  const onTabOne = () => {
    setCurrentTab(0);
  };
  const onTabTwo = () => {
    setCurrentTab(1);
  };
  const onTabThree = () => {
    setCurrentTab(2);
  };
  // const onTabFour = () => {
  //   setCurrentTab(3);
  // };

  return (
    <div className="section section-br">
      <div className="container">
        <div className="work-wrapper">
          <div className="homepage-work-with-bg ">
            <div className="work-wrapper-content">
              {/* <Fade bottom cascade> */}
              <h2 className="heading  w-90-wrapper">INDUSTRIES WE SERVE</h2>
              {/* </Fade> */}
              <div className="tabs-container w-90-wrapper common-bottom-space">
                <div className="tabs-wrapper  ">
                  <h3
                    role="button"
                    onClick={onTabOne}
                    className={
                      currentTab === 0
                        ? "tab active para uppercase-text"
                        : "para uppercase-text tab"
                    }
                  >
                    Aerospace
                  </h3>
                  <h3
                    role="button"
                    onClick={onTabTwo}
                    className={
                      currentTab === 1
                        ? "tab active para uppercase-text"
                        : "para uppercase-text tab"
                    }
                  >
                    Automotive
                  </h3>
                  <h3
                    role="button"
                    onClick={onTabThree}
                    className={
                      currentTab === 2
                        ? "tab active para uppercase-text"
                        : "para uppercase-text tab"
                    }
                  >
                    Manufacturing
                  </h3>
                  {/* <div
                  role="button"
                  onClick={onTabFour}
                  className={currentTab === 3 ? "tab active para" : "para tab"}
                >
                  Advanced Technology
                </div> */}
                </div>
              </div>
              {data.length > 0
                ? data.map((slide, index) => {
                    return (
                      <IndustrySlide
                        heading={slide.heading}
                        link={
                          index !== 2
                            ? `insight/${cmsData.gcms.homepageWhoWeWorkWiths[0].insightPages[index].slug}`
                            : undefined
                        }
                        pageLink={slide.link}
                        insightPageTitle={
                          cmsData.gcms.homepageWhoWeWorkWiths[0].insightPages[
                            index
                          ].title
                        }
                        visible={currentTab === index}
                        img={slide.img}
                        mobImg={slide.mobImg}
                        xlImg={slide.xlImg}
                        paragraph={slide.paragraph}
                        rightWhiteIcon={rightWhiteIcon}
                      />
                    );
                  })
                : null}
              {/* <div className="work-slider-wrapper">
                <div className="work-content-images">
                  <div className="tab-heading">
                    {currentTab === 2 ? (
                      <div className="tab-cta">
                        <div className="heading">
                          {data[currentTab].heading}
                        </div>
                      </div>
                    ) : (
                      <div className="tab-cta">
                        <div className="heading">
                          {data[currentTab].heading} | Case Studies
                        </div>
                        <div className="cta-heading semi-para">
                          {currentTab === 3
                            ? cmsData.gcms.homepageWhoWeWorkWiths[0]
                                .insightPages[2].title
                            : cmsData.gcms.homepageWhoWeWorkWiths[0]
                                .insightPages[currentTab].title}
                        </div>
                        <div className="link-btn">
                          {currentTab === 3 ? (
                            <Link
                              to={`insight/${cmsData.gcms.homepageWhoWeWorkWiths[0].insightPages[2].slug}`}
                            >
                              View <img src={rightWhiteIcon}></img>
                            </Link>
                          ) : (
                            <Link
                              to={`insight/${cmsData.gcms.homepageWhoWeWorkWiths[0].insightPages[currentTab].slug}`}
                            >
                              View <img src={rightWhiteIcon}></img>
                            </Link>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="image-wrapper">
                    <img src={data[currentTab].img} alt="Industry Images"></img>
                  </div>
                  <div className="mobile-image-wrapper">
                    <img
                      src={data[currentTab].mobImg}
                      alt="Industry Images"
                    ></img>
                  </div>
                  <div className="xl-image-wrapper">
                    <img
                      src={data[currentTab].xlImg}
                      alt="Industry Images"
                    ></img>
                  </div>
                </div>
                <div className="tab-content-wrapper">
                  {data && (
                    <div>
                      <div className="content">
                        <div className="para">{data[currentTab].paragraph}</div>
                        <div className="m-top-10px">
                          <WipeBtn
                            text="Explore"
                            className={"green-border green-btn"}
                            url={data[currentTab].link}
                          ></WipeBtn>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageWorkWith;
